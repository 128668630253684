.arrow {
  display: inline-block;
  padding: 23px 29px;
  border: 1px solid #DFD6D1;
  border-radius: 50%;
  transition: background-color .3s ease-in-out;
}

.arrow-left {
  margin-right: 14px;
}

@include respond('large') {
  .arrow {
    transform: scale(0.74);
  }

}

@include respond-down('small') {
   .arrow {
     transform: scale(0.74);
   }

  .arrow-left {
    margin-right: 0;
  }
}

.arrow-white-transition {
  background-color: rgba(255,255,255,255);
  opacity: 0.8;
  transition: opacity .3s ease-in-out;
}

.arrow-white-transition:hover {
  opacity: 1;
}

.arrow-primary:hover {
  background-color: $primary-color;
}

.arrow-white {
  transition: all .3s ease-in-out;
  &:hover {
    background-color: #FFFFFF;
  }
}






