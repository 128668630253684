.recall-page {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__form {

  }
}

@include respond-up('large') {
  .recall-page {
    &__container {
      height: 89vh;
    }

    &__form {

    }
  }
}