.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6vw 22px;
  max-width: 1500px;
  margin: 0 auto;

  &__title {

  }

  &__text {

  }
}

@include respond-up('large') {
  .info {
    &__title {
      font-size: 6vw;
      padding-bottom: 2vw;
    }

    &__text {
      font-size: 22px;
    }
  }
}

@include respond-down('small') {
  .info {
    &__title {
      font-size: 10vw;
      padding-bottom: 4vw;
    }
  }
}