.recall-form {

  .request-form__data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.1;
  }

  &__subtitle {
    color: $gray-color;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.3;
  }

  &__data-container {

  }

  &__data {
    display: flex;

    label {
      display: none;
    }

    .form-field {
      input[type="text"] {
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: 1px solid #000000;
        background-color: transparent;
        height: 45px;
        padding: 0;
        &::placeholder {
          color: #000000;
          transition: transform .25s ease-in-out;
          transform-origin: 0 100%;
        }

        &:focus::placeholder {
          transform: translateY(-20px) scale(.7);
        }
      }
    }
  }

  &__button-container {
    display: flex;
  }

  &__button {

  }

  &__policy {
    color: rgba(0,0,0,0.30);
    letter-spacing: 0;
    line-height: 1.4;
  }

  &__link {
    color: #6CAA34;
  }

  .request-form__success {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__success-image {
    background: {
      size: contain;
      repeat: no-repeat;
      position: 50% 50%;
    };
  }

  &__success-title {
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.1;
  }

  &__success-subtitle {
    color: $gray-color;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.3;
  }
}

@include respond-up('x-large') {
  .recall-form {
    &__title {
      font-size: 50px;
    }

    &__subtitle,
    &__success-subtitle {
      font-size: 19px;
      line-height: 1.68;
    }
  }
}

@include respond-up('large') {
  .recall-form {
    max-width: 572px;
    align-items: center;
    &__title {
      font-size: 45px;
      margin-bottom: 23px;
    }

    &__subtitle {
      margin-bottom: 80px;
    }

    &__data {
      margin: 0 -23px 62px;
      .form-field {
        padding: 0 23px;
        flex: 0 0 50%;
      }
    }

    &__button {
      margin-right: 56px;
    }

    &__policy {
      font-size: 15px;
    }

    &__success-image {
      width: 300px;
      height: 165px;
      margin-bottom: 31px;
    }

    &__success-title {
      font-size: 45px;
      margin-bottom: 17px;
    }
  }
}

@include respond('large') {
  .recall-form {
    &__title {
      font-size: 45px;
    }

    &__subtitle,
    &__success-subtitle {
      font-size: 18px;
      line-height: 1.33;
    }
  }
}

@include respond-down('small') {
  .recall-form {
    margin-top: 50px;
    &__title {
      font-size: 28px;
      margin-bottom: 13px;
    }

    &__subtitle {
      font-size: 18px;
      margin-bottom: 59px;
    }

    &__data {
      flex-direction: column;
      margin-bottom: 18px;

      .form-field {
        margin-bottom: 41px;
        flex: 1 1 100%;
      }
    }

    &__button-container {
      flex-direction: column;
      align-items: center;
    }

    &__button {
      margin-bottom: 15px;
    }

    &__policy {
      font-size: 15px;
      text-align: center;
      margin: 0 -10px;
    }

    &__success-image {
      width: 207px;
      height: 114px;
      margin-bottom: 24px;
    }

    &__success-title {
      font-size: 28px;
      margin-bottom: 9px;
    }

    &__success-subtitle {
      font-size: 18px;
    }
  }
}