.service {
  &__title {
    text-align: center;
  }

  &__description {
    font-size: 18px;
    line-height: 1.6;
    background-color: $primary-color;
  }

  &__prices {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__price {
    &_center {
      text-align: center;
    }
  }

  &__form {
    margin: 0 auto;
  }
}

@include respond-up('large') {
  .service {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &__title {
      font-size: 45px;
      line-height: 1.1;
      padding: 60px 0 40px;
    }

    &__main {
      margin: 0 16vw 5vw;
      border: 4px solid $primary-color;
      max-width: 1010px;
    }

    &__description,
    &__prices {
      padding: 50px 100px 48px;
    }

    &__price {
      flex: 0 0 45%;

      &_center,
      &_wide {
        flex: 0 0 100%;
      }
    }

    &__form {
      padding-bottom: 100px;
    }
  }
}

@include respond-down('small') {
  .service {
    &__title {
      font-size: 28px;
      line-height: 1.2;
      padding: 40px 0 20px;
    }

    &__main {
      border: 3px solid $primary-color;
      margin-bottom: 50px;
    }

    &__description {
      padding: 20px 12px;
    }

    &__prices {
      padding: 30px 12px 28px;
    }

    &__price {
      flex: 0 0 100%;
      padding-bottom: 40px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__form {
      padding-bottom: 80px;
    }
  }
}