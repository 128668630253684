.specialist {
  &__title {
    font-family: $font;
  }
  &__text {
    color: $gray-color;
  }

  &__image {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
  }

  &__form {
    display: flex;
    justify-content: center;
  }
}

@include respond-up('large') {
  .specialist {
    padding-top: 100px;
    padding-bottom: 140px;

    &__info {
      display: flex;
      justify-content: center;
      margin-bottom: 90px;
    }

    &__description {
      max-width: 418px;
      margin: 0 40px;
    }

    &__image {
      width: 408px;
      height: 496px;
      margin: 0 40px;

    }

    &__title {
      font-size: 45px;
      line-height: 50px;
      margin-bottom: 45px;
      margin-top: 50px;
    }

    &__text {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

@include respond-down('small') {
  .specialist {
    padding-top: 30px;
    padding-bottom: 70px;

    &__title {
      line-height: 36px;
      margin-bottom: 20px;
      font-size: 28px;
    }

    &__text {
      line-height: 30px;
      font-size: 18px;
      margin-bottom: 40px;
    }

    &__image {
      height: 400px;
      width: 100%;
      margin-bottom: 50px;
    }
  }

}
