.title {
  font-family: $h-font;
  color: $primary-color;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  font-size: 12vw;
  
  &_white {
    color: #fff;
  }

  &_long {
    font-size: 10vw;
  }
}

@include respond-down('small') {
  .title {
    font-size: 20vw;

    &_long {
      font-size: 11vw;
    }
  }
}