@font-face {
	font-family: 'FuturaDemiC';
	src: url('../fonts/FuturaDemiC.eot');
	src: local('Futura Demi Cyrillic'), local('FuturaDemiC'),
		url('../fonts/FuturaDemiC.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaDemiC.woff') format('woff'),
		url('../fonts/FuturaDemiC.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FuturaMediumC';
	src: url('../fonts/FuturaMediumC.eot');
	src: local('Futura Medium Cyrillic'), local('FuturaMediumC'),
		url('../fonts/FuturaMediumC.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaMediumC.woff') format('woff'),
		url('../fonts/FuturaMediumC.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'FuturaLightC';
	src: url('../fonts/FuturaLightC-Italic.eot');
	src: local('Futura Light Italic Cyrillic'), local('FuturaLightC-Italic'),
		url('../fonts/FuturaLightC-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaLightC-Italic.woff') format('woff'),
		url('../fonts/FuturaLightC-Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaMediumC';
	src: url('../fonts/FuturaMediumC-Italic.eot');
	src: local('Futura Medium Italic Cyrillic'), local('FuturaMediumC-Italic'),
		url('../fonts/FuturaMediumC-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaMediumC-Italic.woff') format('woff'),
		url('../fonts/FuturaMediumC-Italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaLightC';
	src: url('../fonts/FuturaLightC.eot');
	src: local('Futura Light Cyrillic'), local('FuturaLightC'),
		url('../fonts/FuturaLightC.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaLightC.woff') format('woff'),
		url('../fonts/FuturaLightC.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'FuturaDemiC';
	src: url('../fonts/FuturaDemiC-Italic.eot');
	src: local('Futura Demi Italic Cyrillic'), local('FuturaDemiC-Italic'),
		url('../fonts/FuturaDemiC-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaDemiC-Italic.woff') format('woff'),
		url('../fonts/FuturaDemiC-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaBookC';
	src: url('../fonts/FuturaBookC-Italic.eot');
	src: local('Futura Book Italic Cyrillic'), local('FuturaBookC-Italic'),
		url('../fonts/FuturaBookC-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaBookC-Italic.woff') format('woff'),
		url('../fonts/FuturaBookC-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'FuturaBookC';
	src: url('../fonts/FuturaBookC.eot');
	src: local('Futura Book Cyrillic'), local('FuturaBookC'),
		url('../fonts/FuturaBookC.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaBookC.woff') format('woff'),
		url('../fonts/FuturaBookC.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Georgia';
	src: url('../fonts/Georgia-BoldItalic.eot');
	src: local('Georgia Bold Italic'), local('Georgia-BoldItalic'),
	url('../fonts/Georgia-BoldItalic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Georgia-BoldItalic.woff') format('woff'),
	url('../fonts/Georgia-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Georgia';
	src: url('../fonts/Georgia-Italic.eot');
	src: local('Georgia Italic'), local('Georgia-Italic'),
	url('../fonts/Georgia-Italic.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Georgia-Italic.woff') format('woff'),
	url('../fonts/Georgia-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Georgia';
	src: url('../fonts/Georgia-Bold.eot');
	src: local('Georgia Bold'), local('Georgia-Bold'),
	url('../fonts/Georgia-Bold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Georgia-Bold.woff') format('woff'),
	url('../fonts/Georgia-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Georgia';
	src: url('../fonts/Georgia.eot');
	src: local('Georgia'),
	url('../fonts/Georgia.eot?#iefix') format('embedded-opentype'),
	url('../fonts/Georgia.woff') format('woff'),
	url('../fonts/Georgia.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}