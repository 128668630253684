.laser {
  &__wrap {
    position: relative;
  }

  &__video {
    display: block;
  }

  &__title {
    color: #FFFFFF;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &__description {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

@include respond-up('x-large') {
  .laser {
    &__title {
      font-size: 50px;
    }

    &__description {
      font-size: 19px;
      line-height: 1.68;
    }
  }
}

@include respond-up('large') {
 .laser {
   overflow: hidden;
   position: relative;

   &::before {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     background: #000;
     opacity: .5;
   }

   &__video {
     height: 100%;
     width: 100%;
     object-fit: cover;
   }

   &__main {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
   }

   &__title {
     max-width: 800px;
     margin: 0 20px;

     &_mobile {
       display: none;
     }
   }

   &__description {
     color: #FFFFFF;
     max-width: 800px;
     margin: 0 20px;
     text-align: center;
     padding: 44px 0 60px;
   }
 }
}

@include respond('large') {
  .laser {
    &__title {
      font-size: 45px;
    }

    &__description {
      font-size: 18px;
      line-height: 1.66;
    }
  }
}

@include respond-down('small') {
  .laser {
    &__videos {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
        opacity: .5;
      }
    }

    &__video {
      height: 56vw;
      width: 100%;
      object-fit: cover;

      &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
      }

      &::-webkit-media-controls-panel {
        display: none !important;
        -webkit-appearance: none;
      }

      &::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
      }
    }

    &__wrap {
      position: relative;
    }

    &__main {
      padding-bottom: 24px;
    }

    &__title {
      position: absolute;
      top: 0;
      left: 42px;
      right: 42px;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      line-height: 1.28;

      &_desktop {
        display: none;
      }
    }

    &__description {
      padding: 20px 22px 40px;
      font-size: 18px;
      color: $gray-color;
      letter-spacing: 0;
      line-height: 1.44;
    }

    &__button {
      margin: 0 22px;
    }
  }
}