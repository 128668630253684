.services {
  &__title {
    position: relative;
    padding: 5% 0 6%;
  }

  &__flowers {
    position: absolute;
    bottom: -1vw;
    left: 28vw;
    pointer-events: none;
  }

  &__flower {
    height: 12vw;
    width: 24vw;
    object-fit: contain;
  }

  &__main {
    display: flex;
  }

  &__sections {
    display: flex;
  }

  &__section {
    &.active {
      .services {
        &__tab {
          color: #000;
          transition: $default-transition;

          &:before {
            width: 100%;
            left: 0;
            transition: all .55s cubic-bezier(.91, .4, .15, 1);
          }
        }
      }
    }
  }

  &__tab-wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__tab {
    color: rgba(0,0,0,0.30);
    text-align: right;
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      bottom: -4px;
      right: 0;
      background-color: #000;
      transition: all .34s cubic-bezier(.72, .2, .15, 1);
    }
  }

  &__tab-wrap {
    &:hover {
      .services {
        &__tab {
          transition: $default-transition;
          color: #000;
        }
      }
    }
  }

  &__link {
    transition: $default-transition;

    &:hover {
      color: #000;
    }
  }
}

@include respond-up('x-large') {
  .services {
    &__tab {
      font-size: 40px;
    }

    &__section {
      width: 300px;
    }

    &__left {
      width: calc(8vw + 300px + 13vw);
    }

    &__picture {
      top: 19vw;
    }

    &__service {
      font-size: 19px;
      line-height: 1.68;
    }
  }
}

@include respond-up('large') {
  .services {
    &__left {
      position: relative;
    }

    &__sections {
      flex-direction: column;
      padding-top: 6vw;
      padding-right: 13vw;
      padding-left: 8vw;
      height: 100vh;
      align-items: flex-end;
      width: auto !important;
    }

    &__section {
    }

    &__tab {
      display: inline;
      margin-bottom: 14px;
    }

    &__picture {
      position: absolute;
      left: -7vw;
      object-fit: contain;
    }

    &__contents {
      flex: 1;
    }

    &__content {
      width: 100%;
      justify-content: space-between;
    }

    &__center {
      padding-top: 6vw;
      padding-right: 20px;
    }

    &__category-name {
      position: relative;
      font-size: 29px;
      padding-top: 8px;
      padding-bottom: 20px;
    }

    &__category-icon {
      position: absolute;
      top: 0;
      left: calc(-44px - 35px);
    }

    &__services {
      padding-bottom: 76px;
    }

    &__service {
      color: $gray-color;
    }

    &__right {
      position: relative;
      width: 37vw;
    }

    &__img {
      width: 37vw;
      object-fit: cover;
    }

    &__img {
      height: 100vh;
    }
  }
}

@include respond('large') {
  .services {
    &__tab {
      font-size: 35px;
    }

    &__section {
      width: 250px;
    }

    &__service {
      font-size: 18px;
      line-height: 1.66;
    }

    &__left,
    &__swctions {
      width: calc(8vw + 250px + 13vw);
    }

    &__picture {
      top: 22vw;
    }
  }
}

@include respond-down('small') {
  .services {
    &__tab {
      font-size: 20px;
      line-height: 1.7;

      &:before {
        bottom: 0;
        height: 1px;
      }
    }

    &__main {
      flex-direction: column;
      padding: 26px 22px 0;
    }

    &__sections {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -12px;
      padding-bottom: 38px;
    }

    &__section {
      padding: 0 12px;
    }

    &__category {
      padding-bottom: 40px;
    }

    &__category-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 22px;
      padding-bottom: 14px;
    }

    &__category-icon {
      padding-bottom: 24px;
    }

    &__service {
      color: $gray-color;
      line-height: 1.9;
    }

    &__flowers {
      bottom: -6vw;
      left: 16vw;
    }

    &__flower {
      height: 20vw;
      width: 36vw;
    }

    &__right,
    &__picture {
      display: none;
    }
  }
}