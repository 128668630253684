$modal-bg: #fff !default;

$modal-content-radius: 5px !default;
$modal-content-shadow: 0 0 0 #fff !default;
$modal-content-padding: 30px !default;
$modal-content-background: #fff !default;

$modal-closer-color: $modal-bg !default;
$modal-closer-size: 30px !default;

$modal-preloader-size: 30px;

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: $modal-bg;
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &.opened {
    display: block;
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  padding: 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &.opened {
    display: flex;
  }
}

.modal__container {
  padding: $modal-content-padding;
  border-radius: 0;
  background: $modal-content-background;
  box-shadow: $modal-content-shadow;
  position: relative;
  width: 100%;
  min-height: 100vh;
  animation: anime .4s ease-in-out;
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: fixed;
  top: 30px;
  right: 35px;
  background: url('../images/cross-modal.svg') no-repeat top right;
  width: 68px;
  height: 68px;
  font-size: $modal-closer-size;
  color: $modal-closer-color;
  opacity: 0.4;
  text-decoration: none;
  cursor: pointer;
  transition: $default-transition;
  z-index: 3000;

  &:hover {
    opacity: 1;
  }
}

.modal__loader {
  display: block;
  position: fixed;
  z-index: 3000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: $modal-bg;
  transition: 0.3s all;
  opacity: 0;
  visibility: hidden;
}

body.modal-loading {
  overflow: hidden;
  $preloaderSize: 100px;

  .modal__loader {
    opacity: 1;
    visibility: visible;

    &:before, &:after {
      content: '';
      position: fixed;
      width: $preloaderSize;
      height: $preloaderSize;
      left: 50%;
      top: 50%;
      margin-left: -$preloaderSize/2;
      margin-top: -$preloaderSize/2;
      background-color: #000;
      border-radius: $preloaderSize/2;
      transform: scale(0);
    }

    &:before {
      background-color: lighten($primary-color, 15%);
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
      animation-delay: 0.4s;
    }

    &:after {
      background-color: $primary-color;
      animation-name: pulse;
      animation-iteration-count: infinite;
      animation-duration: 1.4s;
    }
  }
}

@include respond-up('large') {
  .modal__closer {
    width: 20%;
    height: 100%;
  }
}

@include respond-down('small') {
  .modal__closer {
    top: 0;
    right: 36px;
    transform: scale(0.6);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes anime {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
