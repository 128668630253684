.button {
  color: #fff;
  background-color: $pink-color;
  text-transform: uppercase;
  font-family: $second-font;
  font-size: 12px;
  letter-spacing: 2.47px;
  text-align: center;
  padding: 0 54px;
  height: 49px;
  line-height: 50px;
  border-radius: 100px;
  text-decoration: none;

  &_low {
    height: 39px;
    line-height: 3.5;
    padding: 4px 48px 0;
  }
}