h1, h2, h3, h4, h5, h6 {
  font-family: $h-font;
}

$h1-size: 30px;
$h2-size: 28px;
$h3-size: 26px;
$h4-size: 24px;
$h5-size: 22px;
$h6-size: 20px;

h1 {
  font-size: $h1-size;
}

h2 {
  font-size: $h2-size;
}

h3 {
  font-size: $h3-size;
}

h4 {
  font-size: $h4-size;
}

h5 {
  font-size: $h5-size;
}

h6 {
  font-size: $h6-size;
}