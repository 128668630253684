.footer {
  padding-top: 5px;
  padding-bottom: 5px;

  &__nav-link {
    font-size: 18px;
    color: #000000;
    text-decoration: none;

    &:hover {
      .footer {
        &__nav-name {
          &:before {
            width: 100%;
            left: 0;
            transition: all .55s cubic-bezier(.91, .4, .15, 1);
          }
        }
      }
    }
  }

  &__nav-name {
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 1px;
      position: absolute;
      bottom: -4px;
      right: 0;
      background-color: #000;
      transition: all .34s cubic-bezier(.72, .2, .15, 1);
    }
  }

  &__line {
    height: 1px;
    background-color: $light-gray-color;
  }

  &__phone {
    font-family: $second-font;
    letter-spacing: 1px;
    text-decoration: none;
    color: #000000;
  }

  &__phone-small {
    display: block;
    font-family: $second-font;
    letter-spacing: 1px;
    text-decoration: none;
    color: #000000;
  }

  &__license-label {
    display: block;
    color: $gray-color;
    line-height: 20px;
    font-size: 14px;
  }

  &__info-item {
    color: $gray-color;
  }

  &__info-link {
    text-decoration: none;
    color: $gray-color;
    font-size: 15px;
    transition: $default-transition;

    &_green {
      color: $green-color;
    }

    &:hover {
      color: #000;
    }
  }
}

@include respond-up('x-large') {
  .footer {
    padding-top: 98px;
    padding-bottom: 128px;

    &__wrapper {
      padding: 0 8%;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    &__nav {
      display: flex;
      margin-left: -15px;
    }

    &__nav-link {
      display: block;
      padding: 5px 15px;
    }

    &__line {
      width: 100%;
      margin-bottom: 50px;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
    }

    &__license {
      width: 537px;
    }

    &__info-list {
      display: flex;
      margin-right: -35px;
    }

    &__info-item {
      padding: 0 35px;
    }

    &__phone-small {
      display: none;
    }
  }
}

@include respond('large') {
  .footer {
    padding-top: 98px;
    padding-bottom: 128px;

    &__wrapper {
      padding: 0 8%;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    &__nav {
      display: flex;
      margin-left: -15px;
    }

    &__nav-link {
      display: block;
      padding: 5px 15px;
    }

    &__phone-small {
      display: none;
    }

    &__line {
      width: 100%;
      margin-bottom: 50px;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
    }

    &__license {
      width: 537px;
    }

    &__info-list {
      display: flex;
      margin-right: -35px;
    }

    &__info-item {
      padding: 0 35px;
    }
  }
}

@include respond-down('small') {
  .footer {
    padding-top: 60px;
    &__wrapper {
      padding: 0 22px;
    }

    &__phone {
      display: none;
    }

    &__phone-small {
      margin-bottom: 20px;
    }

    &__nav {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 135px;
      margin-bottom: 28px;
    }

    &__nav-item:nth-child(4) {
      padding-left: 25%;
    }

    &__nav-item:nth-child(5) {
      padding-left: 25%;
    }

    &__nav-link {
      display: block;
      padding: 10px 0;
    }

    &__line {
      display: none;
    }

    &__line-mobile {
      width: 100%;
      height: 1px;
      background-color: $light-gray-color;
    }

    &__license-label:nth-child(1) {
      display: block;
      text-align: center;
      padding: 0 20px;
      margin-bottom: 10px;
    }

    &__license-label:nth-child(2) {
      display: block;
      text-align: center;
      margin-bottom: 34px;
    }

    &__line-mobile {
      margin-bottom: 23px;
    }

    &__info-item {
      text-align: center;
      padding: 15px 0;
    }
  }
}

