.stocks {
  background-color: $primary-color;
  position: relative;

  &__image-item {
      display: block;
      width: 100%;
      height: 530px;
  }

  &__arrows {
    margin-top: 17px;
    background-color: $primary-color;
  }

  &__title {
      color: #FFFFFF;
  }

  &__info-list {
      background-color: #fff;

  }

  &__image-item {
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
  }

  &__info {
    width: 100%;
    height: 100%;
  }

  &__leaflet-high {
      position: absolute;
      background: url('../images/leaflet/leaflet-7.png');
      background-position: center;
      background-size: cover;
      top: 10px;
      right: 0;
      z-index: 10;
      width: 37.81vw;
      height: 26.04vw;
  }

  &__info-leaflet {
    position: absolute;
    background: url('../images/leaflet/leaflet-8.png');
    background-position: center;
    background-size: cover;
    z-index: 10;
    left: -30px;
    width: 8.81vw;
    height: 5.2vw;
  }

  &__wrapper {
    position: relative;
  }

  &__info-item {

  }

  &__info-text {
    flex-grow: 1;
  }

  &__arrows {
    z-index: 1000;
  }
}

@include respond-up('large') {
  .stocks {
      padding-bottom: 208px;
      padding-top: 115px;

    &__wrapper {
        padding: 0 8%;
    }

    &__title {
        margin-bottom: 63px;
    }

    &__sliders {
        position: relative;
        display: flex;
        align-items: stretch;
    }

    &__info-list {
        width: 50%;
        //max-width: 831px;
        //height: 563px;
    }

    &__info-item {
      width: 500px;
      //height: 563px;
      align-items: center;
      padding: 100px 0 100px 115px;
    }

    &__info-wrapper {
      height: 100%;
    }

    &__info-price {
        display: block;
        font-size: 90px;
        line-height: 100px;
    }

    &__info-label {
        display: block;
        font-size: 34px;
        line-height: 38px;
        margin-bottom: 20px;
    }

    &__info-text {
        font-size: 19px;
        line-height: 32px;
        display: block;
        margin-bottom: 40px;
        color: $gray-color;
    }

    &__images-list {
        position: relative;
        top: 50px;
        margin-left: -50px;
        width: 55%;
        & .slick-list {
          height: 100%;
        }
        & .slick-track {
          height: 100%;
        }
        & div {
          height: 100%;
        }
    }

    &__image-item {
    }

    &__image-item {
        display: block;
        height: 100%;
    }

    &__mobile-list {
        display: none;
    }

    &__mobile-list {
      display: none;
    }
  }
}


@include respond-down('small') {
  .stocks {
    padding-bottom: 53px;

    &__title {
      padding: 40px 0;
    }

    &__images-list {
      display: none;
    }

    &__mobile-list {

    }

    &__image-item {
      width: 100%;
      height: 217px;
    }

    &__mobile-item {
      width: 100%;
      position: relative;
      margin-bottom: 20px;
    }

    &__mobile-info {
      min-height: 358px;
    }

    &__mobile-item-wrapper {
      position: relative;
      display: block;
      max-width: 330px;
      left: 50%;
      transform: translateX(-50%);
    }

    &__mobile-image {
      width: 100%;
      height: 217px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    &__mobile-info {
      padding: 22px;
      background-color: #FFFFFF;
    }

    &__info-list {
      display: none;
    }

    &__mobile-info-price {
      display: block;
      font-size: 45px;
    }

    &__mobile-info-label {
      display: block;
      font-size: 22px;
      margin-bottom: 10px;
    }

    &__mobile-info-text {
      display: block;
      color: $gray-color;
      font-size: 18px;
      margin-bottom: 40px;
    }

    &__arrows {
      max-width: 330px;
      margin: auto;
    }

    &__info-leaflet {
      display: none;
    }
  }
}

@include respond('large') {
    .stocks {
      padding-top: 65px;
      padding-bottom: 174px;

      &__wrapper {
      }

      &__title {
        margin-bottom: 75px;
      }

      &__sliders {
        position: relative;
        display: flex;
        width: 100%;
      }

      &__info-list {
        max-width: 50%;
      }

      &__info-price {
        display: block;
        font-size: 80px;
        line-height: 1;
      }

      &__info-label {
        display: block;
        font-size: 30px;
        line-height: 1;
        margin-bottom: 20px;
      }

      &__info-text {
        display: block;
        margin-bottom: 40px;
        color: rgba(0,0,0,0.41);
        letter-spacing: 0;
        line-height: 1.4em;
      }

      &__images-list {
        width: 55%;
      }

      &__info-item {
        padding-left: 94px;
      }

      &__info-wrapper {
        width: 333px;
      }

      &__image-item {
        width: 745px;
        //height: 530px;
      }

      &__mobile-list {
        display: none;
      }
    }
  }

