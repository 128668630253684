$max-row-width: 1300;
$grid-columns: 12;

$small-breakpoint: 375;
$large-breakpoint: 1200;
$x-large-breakpoint: 1800;

$grid: (
  x-small: (
    from: 0,
    to: $small-breakpoint - 1,
    gutter: 44,
  ),
  small: (
    from: $small-breakpoint,
    to: $large-breakpoint - 1,
    gutter: 44,
  ),
  large: (
    from: $large-breakpoint,
    to: $x-large-breakpoint - 1,
    gutter: 60
  ),
  x-large: (
    from: $x-large-breakpoint,
    gutter: 60
  )
);

$body-font-size: 16px;

$font: 'FuturaBookC', "Arial", sans-serif;
$h-font: 'Georgia', "Arial", sans-serif;
$second-font: 'FuturaDemiC', "Arial", sans-serif;

$t: transparent;

$primary-color: #f2efed;
$pink-color: #ff8c8c;
$green-color: #6caa34;
$gray-color: rgba(0,0,0,0.41);
$dark-blue-color: #121b2a;
$alert-color: #b50000;
$light-gray-color: #d8d8d8;

$default-transition: all 0.4s;