.price {
  &__name {
    padding-bottom: 15px;
  }

  &__description {
    font-size: 18px;
    color: $gray-color;
    line-height: 1.6;
    padding-bottom: 10px;
  }

  &__price {
    font-family: $second-font;
    font-size: 18px;
    color: $dark-blue-color;
    letter-spacing: 0.045em;
  }
}

@include respond-up('large') {
  .price {
    padding-bottom: 20px;

    &__name {
      font-size: 25px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      padding-right: 60px;

      &:last-child {
        padding-right: 0;
      }
    }

    &__price {
      padding-bottom: 40px;
    }
  }
}

@include respond-down('small') {
  .price {
    &__name {
      font-size: 22px;
    }

    &__item {
      padding-bottom: 25px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}