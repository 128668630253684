.specialists {
  position: relative;

  &__item-specialty {
    margin-top: 25px;
    color: $gray-color;
    display: block;
  }

  &__image {
    position: absolute;
    top: 50px;
    left: 8%;
    height: 16vw;
    width: 22vw;
  }

  &__item-image {
    display: block;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__item-link {
    width: 100%;
    height: 100%;
    display: block;
  }

  &__item-name {
    display: inline;
    position: relative;
    border-bottom: 1px solid $t;

    &:hover {
      border-bottom: 1px solid #000000;
    }
  }
}

@include respond-up('x-large') {
  .specialists {
    padding-top: 151px;
    padding-bottom: 100px;

    & .slick-slide {
      width: 500px;
    }
    &__title {
      margin-bottom: 62px;
    }

    &__image {
      top: 300px;
    }

    &__arrows {
      padding-right: 8%;
      text-align: right;
      margin-bottom: 72px;
    }

    &__wrapper {
      padding-left: 8%;
    }

    &__item {
      width: 368px !important;
      display: flex;
      flex-direction: column;
    }

    &__item:nth-child(2n) {
      .specialists__item-content {
        order: 1;
      }
      .specialists__item-image {
        order: 2;
      }
    }

    &__item-image {
      padding-bottom: 97%;
    }

    &__item-content {
      margin-top: 26px;
    }

    &__item-name {
      display: inline;
      margin-bottom: 20px;
      font-size: 23px;
    }

    &__item-specialty {
      margin-top: 25px;
      font-size: 18px;
    }

    &__list {
      margin-left: -150px;
    }
  }
}

@include respond('large') {
  .specialists {
    padding-top: 120px;
    padding-bottom: 60px;

    &__title {
      margin-bottom: 26px;
    }

    &__image {
      top: 200px;
    }

    &__arrows {
      padding-right: 8%;
      text-align: right;
      margin-bottom: 44px;
    }

    &__wrapper {
      padding-left: 8%;
    }

    &__item-image {
      padding-bottom: 93%;
    }

    &__item {
      width: 386px;
      padding-right: 30%;
    }

    &__item-content {
      margin-top: 26px;
    }

    &__item-name {
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 21px;
    }

    &__item-socialty {
      margin-top: 25px;
      font-size: 17px;
    }

    &__list {
      margin-left: -70px;
    }

  }
}

@include respond-down('small') {
  .specialists {
    padding-top: 30px;
    padding-bottom: 97px;

    &__arrows {
      text-align: right;
      padding-right: 22px;
      margin-bottom: 25px;

    }

    &__title {
      line-height: 80px;
    }

    &__item {
      padding: 0 5%;
    }

    &__item-image {
      padding-bottom: 93%;
    }

    &__item-content {
      margin-top: 17px;
    }

    &__item-name {
      display: inline;
      margin-bottom: 6px;
    }

    &__image {

    }

  }
}