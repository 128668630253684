.offer {
  height: calc(100vh - 118px);
  background-color: $primary-color;
  position: relative;
  z-index: 2000;

  &__wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
  }

  &__cont {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
  }

  &__leaflet {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    position: absolute;
    z-index: 15;
  }

  &__image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    position: absolute;
    z-index: 15;
    opacity: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__title {
    opacity: 0;
    position: relative;
    bottom: -30px;
  }

  &__description-container {
    display: flex;
    align-items: flex-start;
    align-self: flex-end;
    z-index: 10;
    position: relative;
    bottom: -15px;
    opacity: 0;
  }

  &__description-commas {
    margin-right: 32px;
  }

  &__description-text {
    color: #000000;
    letter-spacing: 0;
    line-height: 1.1;
  }
}

@include respond-up('x-large') {
  .loaded {
    &.offer {
      .offer {
        &__image {
          left: 1vw;
          opacity: 1;
          transition: 1.2s all ease-in-out;
        }

        &__description-container {
          opacity: 1;
          transition: .9s all .4s ease-in-out;
          bottom: 0;
        }

        &__title {
          opacity: 1;
          transition: .9s all .4s ease-in-out;
          bottom: 0;
        }

        &__leaflet {
          &_1 {
            top: 0;
            transition: 1.6s top, left, opacity .1s ease-in-out;
            z-index: 1000;
          }

          &_2 {
            top: 27vh;
            transition: 1.5s top, left, opacity .1s ease-in-out;
          }

          &_3 {
            bottom: 0;
            transition: 1.5s all .3s ease-in-out;
          }
        }
      }
    }
  }
}

@include respond('large') {
  .loaded {
    &.offer {
      .offer {
        &__title {
          opacity: 1;
          transition: 1.3s all .4s ease-in-out;
          bottom: 0;
        }

        &__image {
          left: 86px;
          opacity: 1;
          transition: 1.2s all ease-in-out;
        }

        &__description-container {
          opacity: 1;
          transition: 1.3s all .5s ease-in-out;
          bottom: 0;
        }

        &__leaflet {
          &_1 {
            top: 0;
            transition: 1.6s top, left, opacity .1s ease-in-out, .1s transform ease-in-out;
            z-index: 1000;

          }

          &_2 {
            top: 27vh;
            transition: 1.5s top, left, opacity .1s ease-in-out, .1s transform ease-in-out;
          }

          &_3 {
            bottom: 0;
            transition: 1.5s all .3s ease-in-out;
          }
        }
      }
    }
  }
}

@include respond-up('x-large') {
  .offer {
    &__cont {
      bottom: -189px;
    }

    &__leaflet {
      &_1 {
        height: 30vh;
        width: 10vw;
        top: 50px;
        left: -2vw;
      }

      &_2 {
        height: 21vh;
        width: 8vw;
        top: 30vh;
        left: 30px;
      }

      &_3 {
        height: 64vh;
        width: 40vw;
        right: -22vw;
        bottom: -4vh;
      }
    }

    &__image {
      height: 65vh;
      width: 70vw;
      bottom: -10px;
      left: -1vw;
    }

    &__container {

    }

    &__title {
      font-size: 20vw;
    }

    &__description-container {
      margin-right: 5%;
    }

    &__description-commas {
      &_small {
        display: none;
      }
    }

    &__description-text {
      font-size: 55px;
      max-width: 600px;
    }
  }
}

@include respond('large') {
  .offer {

    &__cont {
      bottom: -127px;
    }

    &__leaflet {
      &_1 {
        height: 30vh;
        width: 10vw;
        top: 50px;
        left: 0;
      }

      &_2 {
        height: 21vh;
        width: 8vw;
        top: 30vh;
        left: 30px;
      }

      &_3 {
        height: 55vh;
        width: 35vw;
        right: -17vw;
        bottom: -4vh;
      }
    }

    &__image {
      height: 63vh;
      width: 67vw;
      left: 46px;
      bottom: 0;
    }

    &__container {

    }

    &__title {
      font-size: 19vw;
    }

    &__description-container {
      margin-right: 5%;
      margin-top: -10px;
    }

    &__description-commas {
      &_small {
        display: none;
      }
    }

    &__description-text {
      font-size: 50px;
      max-width: 400px;
    }
  }
}

@include respond-down('small') {
  .offer {
    height: calc(100vh - 60px);
    &__cont {
      bottom: -84px;
    }

    &__leaflet {
      &_1 {
        height: 14vh;
        width: 18vw;
        top: 5vh;
        left: -2vw;
        transition: 1.5s top, left, opacity .1s ease-in-out, .1s transform ease-in-out;
      }

      &_2 {
        height: 13vh;
        width: 18vw;
        top: 20vh;
        left: -2vw;
        transition: 1.5s top, left, opacity .1s ease-in-out, .1s transform ease-in-out;
      }

      &_3 {
        height: 35vh;
        width: 80vw;
        right: -49vw;
        bottom: 0;
      }
    }

    &__image {
      height: 55vh;
      width: 154vw;
      left: -14vw;
      bottom: 0;
    }

    &__container {

    }

    &__title {
      font-size: 19vw;
      margin-top: 40px;
    }

    &__description-container {
      margin-right: 7%;
      margin-top: 7px;
    }

    &__description-commas {
      &_small {
        margin-right: 18px;
      }
      &_big {
        display: none;
      }
    }

    &__description-text {
      font-size: 28px;
      max-width: 180px;
    }
  }
}

@include respond-down('small') {
  .loaded {
    &.offer {
      .offer {
        &__title {
          opacity: 1;
          transition: 1.3s all .4s ease-in-out;
          bottom: 0;
        }

        &__image {
          left: -10vw;
          opacity: 1;
          transition: 1.2s all ease-in-out;
        }

        &__description-container {
          opacity: 1;
          transition: 1.3s all .5s ease-in-out;
          bottom: 0;
        }

        &__leaflet {
          &_1 {
            top: 20px;
            transition: 1.5s top, left, opacity .1s ease-in-out, .1s transform ease-in-out;
          }

          &_2 {
            top: 27vh;
            transition: 1.5s top, left, opacity .1s ease-in-out, .1s transform ease-in-out;
          }

          &_3 {
            bottom: 0;
            transition: 1.5s all .3s ease-in-out;
          }
        }
      }
    }
  }
}