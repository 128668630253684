.error-page {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__404 {
    font-family: $h-font;
    font-size: 180px;
    line-height: 1.4;
    color: $primary-color;
    font-weight: bold;
  }

  &__text {
    font-size: 24px;
    text-align: center;
  }

  &__buttons {
    padding-top: 60px;
  }

  &__button {

  }
}

@include respond-up('large') {
  .error-page {
    &__main {
      padding: 100px 0 120px;
    }
  }
}

@include respond-down('small') {
  .error-page {
    &__main {
      padding: 40px 0 80px;
    }
  }
}