.header {
  background-color: $primary-color;
  height: 118px;

  &__logo {
    transform: translateY(4px);
  }

  &__menu {
    height: 118px;
  }

  &__container {
    position: relative;
    top: -20px;
    display: flex;
    opacity: 0;
    align-items: center;
    background-color: $primary-color;
    height: 118px;
  }

  &__wrapper {
    height: 0;
  }

  &__link {
    &:hover {
      .header {
        &__name {
          &:before {
            width: 100%;
            left: 0;
            transition: all .55s cubic-bezier(.91, .4, .15, 1);
          }
        }
      }
    }
  }

  &__name {
    position: relative;

    &:before {
      content: '';
      width: 0;
      height: 1px;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #000;
      transition: all .34s cubic-bezier(.72, .2, .15, 1);
    }
  }

  &__address,
  &__schedule {
    display: none;
  }
}

.body_hidden {
  height: 100vh;
  overflow: hidden;
}

@include respond-up('large') {
  .header {
    &__container {
      height: 118px;
      padding: 0 72px;
    }

    &__hamburger,
    &__phone-icon {
      display: none;
    }

    &__nav {
      flex: 1 1;
      padding: 8px 0 0 76px;
      color: $dark-blue-color;
    }

    &__sticky {
      display: none;
    }

    &__items {
      display: flex;
      align-items: center;
    }

    &__link {
      padding: 14px;
    }

    &__name {
      font-size: 18px;
    }

    &__phone {
      padding: 8px 40px 0;
    }

    &__phone-number {
      font-family: $second-font;
      color: $dark-blue-color;
      letter-spacing: 0.83px;
      text-align: right;
      font-size: 18px;
    }

    &__wrapper {
      display: none;
    }

    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      line-height: 1;
    }

    &__address {
      display: block;
      padding-bottom: 10px;
    }

    &__schedule {
      display: block;
      padding-top: 10px;
    }
  }

  .loaded {
    &.header {
      .header__container {
        opacity: 1;
        top: 0;
        transition: 1.2s all .4s ease;
      }
    }
  }
}

@include respond-down('small') {
  .loaded {
    &.header {
      .header {
        &__container {
          opacity: 1;
          top: 0;
        }
      }
    }
  }

  .header {
    height: 60px;
    
    &__sticky {
      z-index: 1000;

      .header {
        &__container {
          z-index: 2000;
          background-color: #fff;
        }
      }
    }

    &__container {
      justify-content: space-between;
      height: 60px;
      padding: 0 22px;
      transition: $default-transition;
      z-index: 3000;
    }

    &__hamburger {
      width: 29px;
      height: 29px;
      background: url('../images/hamburger.svg') no-repeat center / contain;
    }

    &__nav,
    &__phone-number,
    &__button {
      height: 0;
      display: none;
    }

    &__wrapper {
      height: 0;
      overflow: hidden;
      opacity: 0;
      background-color: $primary-color;
      transition: $default-transition;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 40px 24px 0;
    }

    &__social {
      align-self: center;
      justify-content: center;
      display: flex;
      padding: 30px 0 50px;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    &__items {
      display: flex;
      flex-direction: column;
    }

    &__item {
      flex: 1 1 100%;
      text-align: center;
      border-bottom: 1px solid #E8E8E8;

      &:first-child {
        border-top: 1px solid #E8E8E8;
      }
    }

    &__link {
      display: block;
      font-size: 18px;
      padding: 25px 0;
    }

    &_active {
      height: 100vh;
      position: fixed;
      z-index: 10000;
      width: 100vw;
      overflow: auto;

      .header {
        &__hamburger {
          background: url('../images/closer.svg') no-repeat center;
        }

        &__container {
          background-color: white;
          transition: $default-transition;
        }

        &__wrapper {
          pointer-events: auto;
          transition: $default-transition;
          background-color: white;
          opacity: 1;
          height: 100%;
          overflow: auto;
        }

        &__sticky {
          display: none;
        }
      }
    }
  }
}