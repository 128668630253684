.about {
  position: relative;
  overflow-x: hidden;

  &__title {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  &__advantages-item-number {
    color: $pink-color;
  }

  &__advantages-item-description {
    color: $gray-color;
  }

  &__image {
    position: absolute;
    right: 28vw;
    width: 14.626vw;
    height: 17.21vw;
    background-image: url('../images/leaflet/leaflet-11.png');
    background-size: cover;
    background-position: center;
  }

  &__gallery {
    position: relative;
  }

  &__leaf {
    position: absolute;
    top: 400px;
    right: 19.53vw;
    background-image: url('../images/leaflet/leaflet-5.png');
    background-size: cover;
    background-position: center;
    width: 7.77vw;
    height: 5vw;
    z-index:10;
  }

  &__advantages-item-title-mobile {
    display: none;
  }

  &__gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
}

@include respond-up('large') {
  .about {
    padding-top: 140px;
    padding-bottom: 160px;

    &__title {
      margin-bottom: 154px;
    }

    &__wrapper {
      padding: 0 8%;
    }

    &__advantages {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 300px;
    }

    &__advantages-item {
      display: flex;
      margin: 0 22px;
      width: 30%;
    }

    &__advantages-item-number {
      margin-right: 40px;
      font-size: 90px;
    }

    &__advantages-item-content {

    }

    &__advantages-item-title {
      display: block;
      font-size: 29px;
      margin-top: 16px;
      margin-bottom: 30px;
      width: 250px;
    }

    &__advantages-item-description {
      font-size: 19px;
      line-height: 1.68em;
    }

    &__leaflet-bottom {
      position: absolute;
      top: 800px;
      left: 30%;
      background: url('../images/leaflet/leaflet-9.png');
      background-position: center;
      background-size: cover;
      width: 17.08vw;
      height: 10.156vw;
    }

    .slick-slide {

    }

    &__gallery:hover {
      .about__arrow-left {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(50px, -50%, 0);
      }
      .about__arrow-right {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(-50px, -50%, 0) rotate(180deg);
      }
    }

    &__gallery-item {
      height: 41.77vw;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__gallery-mobile {
      display: none;
    }

    .slick-slide {
      padding: 0 22px;
    }

    &__arrow-left {
      position: absolute;
      top: 50%;
      left: 247px;
      transform: translateY(-50%);
      opacity: 0;
      pointer-events: none;
      transition: all .5s ease-in-out;
    }
    &__arrow-right {
      position: absolute;
      top: 50%;
      right: 247px;
      transform-origin: 50% 50%;
      transform: translateY(-50%) rotate(180deg);
      opacity: 0;
      pointer-events: none;
      transition: all .5s ease-in-out;
    }

    &__image {
      top: 14.25vw;
    }
  }
}

@include respond('large') {
  .about {
    padding-top: 135px;
    padding-bottom: 134px;
    &__title {
      margin-bottom: 64px;
    }

    &__wrapper {
      padding: 0 8%;
    }

    &__advantages {
      display: flex;
      flex-wrap: wrap;
    }

    &__advantages-item {
      display: flex;
      margin: 0 20px;
      width: 28%;
    }

    &__advantages-item-number {
      margin-right: 40px;
      font-size: 90px;
    }

    &__advantages-item-content {

    }

    &__advantages-item-title {
      display: block;
      font-size: 29px;
      margin-top: 16px;
      margin-bottom: 30px;
    }

    &__advantages-item-description {
      font-size: 18px;
      line-height: 1.44em;
    }

    .slick-slide {
      padding: 0 22px;
    }

    &__leaflet-bottom {
      position: absolute;
      top: 40%;
      left: 30%;
      background: url('../images/leaflet/leaflet-9.png');
      z-index: 10;
      background-position: center;
      background-size: cover;
      width: 17.08vw;
      height: 10.156vw;
    }

    &__gallery-item {
      display: block;
      height: 41.77vw;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__gallery-mobile {
      display: none;
    }

    &__image {
      top: 17.25vw;
    }

    &__arrow-left {
      left: 120px;
    }

    &__arrow-right {
      right: 120px;
    }
  }
}

@include respond-down('small') {
  .about {
    padding-top: 30px;
    padding-bottom: 40px;

    &__title {
      margin-bottom: 25px;
    }

    &__advantages {
      margin: 0;
      display: block;
      margin-bottom: 45px;
    }

    &__advantages-item {
      display: flex;
      width: 100%;
      margin: 0;
      margin-bottom: 40px;
    }

    &__wrapper {
      padding: 0 22px;
    }

    &__advantages-item-number {
      display: inline-block;
      font-size: 65px;
      margin-right: 22px;
    }

    &__advantages-item-title {
      display: block;
      line-height: 1.22em;
      font-size: 22px;
      padding-top: 10px;
      width: 194px;
      margin-bottom: 20px;
    }

    //&__advantages-item-title-mobile {
    //  display: inline-block;
    //  font-size: 22px;
    //  width: 194px;
    //}

    &__advantages-item-text {
      line-height: 1.44em;
    }

    &__leaflet-bottom {
      position: absolute;
      left: unset;
      right: -20px;
      top: 600px;
      width: 130px;
      height: 78px;
      background: url('../images/leaflet/leaflet-9.png');
      background-position: center;
      background-size: cover;
    }

    .slick-slide {
      padding: 0;
    }

    &__gallery-mobile-item {
      height: 73.77vw;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    &__gallery {
      display: none;
    }

    &__leaf {
      top: 60vw;
      width: 70px;
      height: 49px;
      right: 3vw;
    }

    &__image {
      top: 18vw;
      right: 10vw;
      width: 25vw;
      height: 30vw;
    }

    &__arrows {
      padding-left: 22px;
    }
  }
}