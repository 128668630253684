.contacts {
  overflow-x: hidden;

  &__titles {
    display: flex;
    justify-content: center;
  }

  &__title {
    position: relative;
    display: inline-block;
  }

  &__main {
    display: flex;
  }

  &__leaf {
    position: absolute;
    right: -17vw;
    display: block;
    height: 26vw;
    width: 43vw;
    background: url("../images/contacts/leaf.png") no-repeat center / contain;
    z-index: 100;
  }

  &__phones {
    display: flex;
    flex-direction: column;
  }

  &__phone {
    font-family: $second-font;
    color:$dark-blue-color;
  }

  &__address,
  &__schedule {
    display: block;
    font-size: 18px;
    color: $gray-color;
    line-height: 1.4;
  }

  &__social {
    display: inline-block;
  }
}

@include respond-up('x-large') {
  .contacts {
    &__leaf {
      bottom: -5vw;
    }
  }
}

@include respond-up('large') {
  .contacts {
    &__title {
      padding: 100px 0 78px;
    }

    &__main {
      padding-bottom: 160px;
    }

    &__map {
      height: 33vw;
      flex: 0 0 1200/1920*100%;
    }

    &__info {
      flex: 0 0 720/1920*100%;
      padding-left: 10vw;
      margin: auto 0;
    }

    &__phones {
      padding-bottom: 36px;
    }

    &__phone {
      font-size: 20px;
      letter-spacing: 0.05em;
      padding-bottom: 8px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__address {
      padding-bottom: 42px;
    }

    &__schedule {
      padding-bottom: 60px;
    }
  }
}

@include respond('large') {
  .contacts {
    &__leaf {
      bottom: -3vw;
    }
  }
}

@include respond-down('small') {
  .contacts {
    &__title {
      padding: 64px 0 30px;
    }

    &__leaf {
      bottom: 0;
    }

    &__main {
      margin: 0 22px;
      padding-bottom: 70px;
      flex-direction: column-reverse;
    }

    &__map {
      height: 90vw;
    }
    &__phones {
      padding-bottom: 25px;
    }

    &__phone {
      font-size: 16px;
      letter-spacing: 0.035em;
      padding-bottom: 8px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &__address {
      padding-bottom: 25px;
    }

    &__schedule {
      padding-bottom: 40px;
    }

    &__social {
      padding-bottom: 50px;
    }
  }
}