.testing {
  background-color: $primary-color;

  &__wrap {
    max-width: 1920px;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__text {
    background-color: #fff;
    border: 6px solid #fff;
    padding: 6% 7% 7% 8%;

    &_gray {
      padding-right: 15%;
      background-color: $t;
      color: $gray-color;
    }
  }

  &__item {
    display: block;

    &.active {
      .testing {
        &__name {
          color: #000;
        }
      }
    }
  }

  &__number {
    color: #fff;
    line-height: 1;
  }

  &__item {
    &.active {
      .testing {
        &__name {
          &::before {
            width: 100%;
            left: 0;
            transition: all .55s cubic-bezier(.91, .4, .15, 1);
          }
        }
      }
    }
  }

  &__link {
    &:hover {
      .testing {
        &__name {
          color: #000;
          transition: all .4s;
        }
      }
    }
  }

  &__name {
    color: rgba(0,0,0,0.30);
    position: relative;

    &::before {
      content: '';
      width: 0;
      height: 2px;
      position: absolute;
      bottom: -4px;
      right: 0;
      background-color: #000;
      transition: all .34s cubic-bezier(.72, .2, .15, 1);
    }
  }

  &__description {
    color: rgba(0,0,0,0.41);
    font-size: 18px;
    line-height: 1.7;
  }

  &__leaf-left {
    background: url("../images/testing/leaf-left.png") no-repeat center / contain;
  }

  &__leaf-right {
    background: url("../images/testing/leaf-right.png") no-repeat center / contain;
  }
}

@include respond-up('x-large') {
  .testing {
    &__title {
      font-size: 50px;
      line-height: 1.1;
    }

    &__text {
      font-size: 25px;
      line-height: 1.4;

      &_gray {
        font-size: 19px;
        line-height: 1.7;
      }
    }

    &__description {
      font-size: 19px;
    }

    &__name {
      font-size: 30px;
    }

    &__number {
      font-size: 120px;
    }

    &__button {
      margin-top: 60px;
    }
  }
}

@include respond('large') {
  .testing {
    &__title {
      font-size: 45px;
      line-height: 1.1;
    }

    &__text {
      font-size: 22px;
      line-height: 1.4;

      &_gray {
        font-size: 18px;
        line-height: 1.5;
      }
    }

    &__number {
      font-size: 80px;
    }

    &__name {
      font-size: 25px;
    }

    &__button {
      margin-top: 36px;
    }
  }
}

@include respond-up('large') {
  .testing {
    &__wrap {
      justify-content: space-between;
      padding: 165px 8% 170px;
    }

    &__title {
      position: relative;
      padding-bottom: 70px;
    }

    &__main {
      flex: 0 0 (578px/1500px*100%);
    }

    &__content {
      position: relative;
      flex: 0 0 (697px/1500px*100%);
    }

    &__items {
      margin: 0 -3%;
    }

    &__item {
      padding: 0 3%;
    }

    &__link {
      display: flex;
      flex-direction: column;
    }

    &__number {
      padding-bottom: 42px;
    }

    &__name {
      display: block;
      line-height: 1.6;
      border-bottom: 2px solid $t;
    }

    &__description {
      padding: 30px 70px 0 0;
    }

    &__leaf-left {
      position: absolute;
      left: -11.5vw;
      bottom: -9vw;
      display: block;
      height: 26vw;
      width: 26vw;
    }

    &__leaf-right {
      position: absolute;
      right: -1vw;
      bottom: -6vw;
      display: block;
      height: 8vw;
      width: 10vw;

      &_mobile {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .testing {
    &__wrap {
      flex-direction: column;
      padding: 52px 22px 60px;
      overflow: hidden;
    }

    &__main {
      position: relative;
      max-width: 600px;
    }

    &__title {
      position: relative;
      font-size: 28px;
      line-height: 1.3;
      padding-bottom: 32px;
    }

    &__text {
      font-size: 20px;
      line-height: 1.4;
      padding: 6% 4%;

      &_gray {
        font-size: 18px;
      }
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      padding: 50px 0 28px;
    }

    &__item {
      padding-right: 20px;
    }

    &__number {
      display: none;
    }

    &__name {
      font-size: 20px;
      line-height: 1.7;

      &::before {
        height: 1px;
        bottom: -2px;
        transition: all .55s cubic-bezier(.91, .4, .15, 1);
      }
    }

    &__description {
      padding-bottom: 36px;
      line-height: 1.6;
    }

    &__leaf-left {
      position: absolute;
      right: -90px;
      bottom: -110px;
      display: block;
      height: 190px;
      width: 190px;
      background: url("../images/testing/leaf-mobile.png") no-repeat center / contain;
    }

    &__leaf-right {
      position: absolute;
      right: 40px;
      bottom: -80px;
      display: block;
      height: 90px;
      width: 105px;

      &_desktop {
        display: none;
      }
    }
  }
}