.social {
  cursor: pointer;

  &__link {
    &:hover {
      .social {
        &__circle {
          fill: $green-color;
        }

        &__icon {
          fill: #fff;
        }
      }
    }
  }

  &__circle {
    fill: #fff;
  }

  &__circle,
  &__icon {
    transition: $default-transition;
  }
}

@include respond-up('large') {
  .social {
    &__link {
      padding-right: 3px;
    }
  }
}

@include respond-down('small') {
  .social {
    &__link {
      padding-right: 8px;

      svg {
        width: 38px;
        height: 38px;
      }
    }
  }
}