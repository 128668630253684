.recall-block {
  position: relative;
  background-color: $primary-color;
  &__leaflet {
    position: absolute;
    background: {
      size: contain;
      position: 50% 50%;
      repeat: no-repeat;
    }
  }

  &__branch-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__form {

  }
}

@include respond-up('x-large') {
  .recall-block {
    &__leaflet {
      &_1 {
        width: 6vw;
        height: 15vh;
        left: 6vw;
        top: -4vh;
      }

      &_2 {
        width: 10vw;
        height: 20vh;
        top: 3vh;
        left: 10vw;
      }

      &_3 {
        height: 13vh;
        width: 6vw;
        top: 22vh;
        left: 4vw;
      }

      &_group {
        width: 7vw;
        height: 17vh;
        top: 28vh;
        right: 5vw;
      }
    }

    &__flower-large {
      width: 23vw;
      height: 48vh;
      bottom: 23vh;
      left: 3vw;
    }

    &__cup {
      width: 23vw;
      height: 43vh;
      right: 4vw;
      bottom: 13vh;
    }

    &__branch {
      width: 40vw;
      height: 60vh;
      bottom: -5vh;
      right: -5vw;
    }

    &__container {

    }

    &__form {
      margin: 207px 0 231px;
    }
  }
}

@include respond('large') {
  .recall-block {
    &__leaflet {
      &_1 {
        width: 6vw;
        height: 15vh;
        left: 6vw;
        top: -4vh;
      }

      &_2 {
        width: 10vw;
        height: 20vh;
        top: 3vh;
        left: 10vw;
      }

      &_3 {
        height: 13vh;
        width: 6vw;
        top: 22vh;
        left: 4vw;
      }

      &_group {
        width: 7vw;
        height: 17vh;
        top: 28vh;
        right: 5vw;
      }
    }

    &__flower-large {
      width: 23vw;
      height: 48vh;
      bottom: 23vh;
      left: 3vw;
    }

    &__cup {
      width: 23vw;
      height: 43vh;
      right: 4vw;
      bottom: 13vh;
    }

    &__branch {
      width: 40vw;
      height: 60vh;
      bottom: -5vh;
      right: -7vw;
    }

    &__container {

    }

    &__form {
      margin: 166px 0 201px;
    }
  }
}

@include respond-down('small') {
  .recall-block {
    &__leaflet {
      &_1 {
        width: 17vw;
        height: 13vh;
        left: 0;
        top: -4vh;
      }

      &_2 {
        display: none;
      }

      &_3 {
        display: none;
      }

      &_group {
        width: 28vw;
        height: 17vh;
        bottom: 13vh;
        right: 18vw;
      }
    }

    &__flower-large {
      display: none;
    }

    &__cup {
      width: 65vw;
      height: 43vh;
      left: -19vw;
      bottom: 1vh;
    }

    &__branch {
      width: 104vw;
      height: 50vh;
      bottom: -5vh;
      right: -18vw;
    }

    &__container {

    }

    &__form {
      margin: 71px 0 272px;
    }
  }
}